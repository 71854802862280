import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment } from "vue"
import _imports_0 from '@/assets/images/cover_img.png'
import _imports_1 from '@/assets/images/pencil.svg'
import _imports_2 from '@/assets/images/akar-icons_plus.svg'


const _hoisted_1 = { class: "col-md-4 mb-5" }
const _hoisted_2 = { class: "profile_info" }
const _hoisted_3 = { class: "cover_img" }
const _hoisted_4 = { class: "profile_img" }
const _hoisted_5 = { class: "my_img" }
const _hoisted_6 = ["src"]
const _hoisted_7 = { key: 1 }
const _hoisted_8 = {
  key: 2,
  class: "spinner-border"
}
const _hoisted_9 = { class: "user_name" }
const _hoisted_10 = { class: "user_info_tabs" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_user_badge = _resolveComponent("user-badge")!
  const _component_tab_button_profile = _resolveComponent("tab-button-profile")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _cache[8] || (_cache[8] = _createElementVNode("img", {
            src: _imports_0,
            id: "profile-picture",
            alt: ""
          }, null, -1)),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              (_ctx.userPhoto)
                ? (_openBlock(), _createElementBlock("img", {
                    key: 0,
                    src: _ctx.userPhoto,
                    alt: "",
                    class: "profile_img"
                  }, null, 8, _hoisted_6))
                : _createCommentVNode("", true),
              (!_ctx.userPhoto && !_ctx.uploading)
                ? (_openBlock(), _createElementBlock("p", _hoisted_7, _toDisplayString(_ctx.initials), 1))
                : _createCommentVNode("", true),
              (_ctx.uploading)
                ? (_openBlock(), _createElementBlock("div", _hoisted_8))
                : _createCommentVNode("", true),
              (!_ctx.userPhoto)
                ? (_openBlock(), _createElementBlock("button", {
                    key: 3,
                    class: "edit_icon",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.triggerImgInput()))
                  }, _cache[6] || (_cache[6] = [
                    _createElementVNode("img", {
                      src: _imports_1,
                      alt: ""
                    }, null, -1)
                  ])))
                : _createCommentVNode("", true),
              (_ctx.userPhoto)
                ? (_openBlock(), _createElementBlock("button", {
                    key: 4,
                    class: "edit_icon close-icon",
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.removeProfileImg()))
                  }, _cache[7] || (_cache[7] = [
                    _createElementVNode("img", {
                      src: _imports_2,
                      alt: ""
                    }, null, -1)
                  ])))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("p", null, _toDisplayString(_ctx.username), 1),
              _createVNode(_component_user_badge, { role: _ctx.userRole }, null, 8, ["role"]),
              _createElementVNode("ul", _hoisted_10, [
                _createVNode(_component_tab_button_profile, {
                  icon_inactive: require('@/assets/images/profile-black.svg'),
                  icon_active: require('@/assets/images/profile-orange.svg'),
                  title: "Profile",
                  onOnClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onChangeTab(_ctx.ProfilePageTab.INFO))),
                  active: _ctx.currentTab === _ctx.ProfilePageTab.INFO
                }, null, 8, ["icon_inactive", "icon_active", "active"]),
                _createVNode(_component_tab_button_profile, {
                  icon_inactive: require('@/assets/images/social-networks.svg'),
                  icon_active: 
                  require('@/assets/images/social-networks-orange.svg')
                ,
                  title: "Social",
                  onOnClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.onChangeTab(_ctx.ProfilePageTab.SOCIAL))),
                  active: _ctx.currentTab === _ctx.ProfilePageTab.SOCIAL
                }, null, 8, ["icon_inactive", "icon_active", "active"]),
                _createVNode(_component_tab_button_profile, {
                  icon_inactive: require('@/assets/images/dartboard.svg'),
                  icon_active: require('@/assets/images/dartboard-orange.svg'),
                  title: "Interests",
                  onOnClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.onChangeTab(_ctx.ProfilePageTab.INTERESTS))),
                  active: _ctx.currentTab === _ctx.ProfilePageTab.INTERESTS
                }, null, 8, ["icon_inactive", "icon_active", "active"])
              ])
            ])
          ])
        ])
      ])
    ]),
    _createElementVNode("input", {
      onChange: _cache[5] || (_cache[5] = (e) => _ctx.getFile(e)),
      style: {"display":"none"},
      type: "file",
      accept: "image/*",
      ref: "fileUploader"
    }, null, 544)
  ], 64))
}