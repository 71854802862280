import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "profile_outer" }
const _hoisted_2 = {
  key: 0,
  class: "container"
}
const _hoisted_3 = { class: "row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_side_menu_profile = _resolveComponent("side-menu-profile")!
  const _component_profile_info = _resolveComponent("profile-info")!
  const _component_profile_social = _resolveComponent("profile-social")!
  const _component_profile_interests = _resolveComponent("profile-interests")!
  const _component_loader = _resolveComponent("loader")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    (_ctx.userData)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_side_menu_profile, {
              currentTab: _ctx.currentTab,
              onOnChangeTab: _ctx.setTab
            }, null, 8, ["currentTab", "onOnChangeTab"]),
            (_ctx.currentTab === _ctx.ProfilePageTab.INFO)
              ? (_openBlock(), _createBlock(_component_profile_info, {
                  key: 0,
                  country: _ctx.userData.country,
                  email: _ctx.userData.email,
                  firstname: _ctx.userData.firstname,
                  language: _ctx.userData.language,
                  image: _ctx.userData.image,
                  role: _ctx.userData.role,
                  username: _ctx.userData.username,
                  profession: _ctx.userData.profession,
                  lastname: _ctx.userData.lastname
                }, null, 8, ["country", "email", "firstname", "language", "image", "role", "username", "profession", "lastname"]))
              : _createCommentVNode("", true),
            (_ctx.currentTab === _ctx.ProfilePageTab.SOCIAL)
              ? (_openBlock(), _createBlock(_component_profile_social, {
                  key: 1,
                  facebook_url: _ctx.userData.facebook_url,
                  instagram_url: _ctx.userData.instagram_url,
                  twitter_url: _ctx.userData.twitter_url,
                  youtube_url: _ctx.userData.youtube_url,
                  bio: _ctx.userData.bio
                }, null, 8, ["facebook_url", "instagram_url", "twitter_url", "youtube_url", "bio"]))
              : _createCommentVNode("", true),
            (_ctx.currentTab === _ctx.ProfilePageTab.INTERESTS)
              ? (_openBlock(), _createBlock(_component_profile_interests, {
                  key: 2,
                  interest_description: _ctx.userData.interest_description,
                  user_interests: _ctx.userData.user_interests || [],
                  user_languages: _ctx.userData.user_languages || []
                }, null, 8, ["interest_description", "user_interests", "user_languages"]))
              : _createCommentVNode("", true)
          ])
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_loader, { show: _ctx.isLoading }, null, 8, ["show"])
  ]))
}