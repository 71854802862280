
import { PropType } from "vue";
import {
  TopicInterest,
  UserInterest,
} from "@toppick/common/build/interfaces";
import { defineComponent } from "@vue/runtime-core";
import Multiselect from "@vueform/multiselect";
import { languages } from "countries-list";
import { getTopicsInterests } from "@toppick/common/build/api/topic";
import { updateUserProfileInterest } from "@toppick/common/build/api";
import {
  validateUserInterestsDescriptionMin,
  validateUserInterestsDescriptionMax,
} from "@toppick/common/build/validators";
import { getAuthToken } from "@/utils/auth";

export default defineComponent({
  components: { Multiselect },
  props: {
    interest_description: {
      required: false,
      type: String,
    },
    user_interests: {
      type: Array as PropType<UserInterest[]>,
      required: true,
    },
    user_languages: {
      type: Array as PropType<{ title: string }[]>,
      required: true,
    },
  },
  data() {
    return {
      isModified: false,
      isLoading: false,
      error: "" as string,
      success: "" as string,
      errorMessages: [] as string[],
      successMessages: [] as string[],
      currentInterestDescription: this.interest_description || "",
      currentInterests: this.parseInterests(this.user_interests) as number[],
      currentLanguages: this.parseTitle(this.user_languages) as string[],
      loadedInterests: [] as TopicInterest[],
    };
  },
  methods: {
    onChange() {
      this.isModified = true;
    },
    parseTitle(data: { title: string }[]): string[] {
      return data.map((el) => el.title);
    },
    parseInterests(interests: UserInterest[]): number[] {
      return interests.map((el) => el.interests.id);
    },
    handleInputError(inputName: string): boolean {
      return this.errorMessages.some((error) =>
        error.toLowerCase().includes(inputName)
      );
    },
    handleInputSuccess(inputName: string): boolean {
      return this.successMessages.some((error) =>
        error.toLowerCase().includes(inputName)
      );
    },
    async onSubmit() {
      this.error = "";
      this.isLoading = true;
      try {
        await updateUserProfileInterest(
          await getAuthToken(),
          this.$store.getters.getUserID,
          {
            user_interests: this.currentInterests.map((id) => ({
              id,
            })),
            user_languages: this.currentLanguages.map((goal) => ({
              title: goal,
            })),
            interest_description: this.currentInterestDescription,
          }
        );
        this.success = "Success";
        this.isModified = false;
        setTimeout(() => {
          this.success = "";
        }, 2000);
      } catch (error) {
        this.error = "";
        console.log(error);
      }
      this.isLoading = false;
    },
  },
  computed: {
    isSubmittable(): boolean {
      if (!this.isModified) return false;
      return this.errorMessages.length === 0;
    },
    languages() {
      return Object.keys(languages).map((key) => ({
        value: key,
        label: languages[key].name,
      }));
    },
    interests() {
      return this.loadedInterests.map((goal) => ({
        value: goal.id,
        label: goal.title,
      }));
    },
  },
  watch: {
    currentInterestDescription: function () {
      const errorMin = "Description too short";
      const errorMax = "Description too long";
      const success = "Valid Description";
      this.errorMessages = [];
      this.successMessages = [];
      if (!this.currentInterestDescription) {
        return;
      }
      if (
        !validateUserInterestsDescriptionMin(this.currentInterestDescription)
      ) {
        this.errorMessages.push(errorMin);
      } else if (
        !validateUserInterestsDescriptionMax(this.currentInterestDescription)
      ) {
        this.errorMessages.push(errorMax);
      } else {
        this.successMessages.push(success);
      }
    },
  },
  async mounted() {
    this.loadedInterests = await getTopicsInterests();
  },
});
