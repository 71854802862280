
import { defineComponent } from "@vue/runtime-core";
import Multiselect from "@vueform/multiselect";
import { updateUserInfo } from "@toppick/common/build/api/user";
import { UserProfileInfo } from "@toppick/common/build/interfaces";

import {
  validateFirstname,
  validateLastname,
  validateUserName,
} from "@toppick/common/build/validators";
import { PROFESSIONS } from "@/constants/registration";
import { getInitials } from "@/utils/ui";
import { countries, languages } from "countries-list";
import { getErrorMessage } from "@toppick/common/build/utils";
import { getAuthToken } from "@/utils/auth";

export default defineComponent({
  components: { Multiselect },
  props: {
    username: String,
    country: String,
    firstname: String,
    lastname: String,
    profession: String,
    language: String,
    image: String,
    email: String,
    role: String,
  },
  data() {
    return {
      isModified: false,
      isLoading: false,
      error: "" as string,
      success: "" as string,
      userData: {
        username: this.username || "",
        country: this.country || "",
        firstname: this.firstname || "",
        lastname: this.lastname || "",
        profession: this.profession || "",
        language: this.language || "",
        role: this.role || "",
        email: this.email || "",
      },
    };
  },

  methods: {
    onChange: async function () {
      this.isModified = true;
    },

    async onSubmit() {
      this.error = "";
      this.success = "";
      this.isLoading = true;
      try {
        await updateUserInfo(
          await getAuthToken(),
          this.$store.getters.getUserID,
          this.userData
        );
        this.success = "Success";
        this.isModified = false;
        setTimeout(() => {
          this.success = "";
        }, 2000);
      } catch (error) {
        this.error = getErrorMessage(error);
      }
      this.isLoading = false;
    },
  },

  computed: {
    countries() {
      return Object.keys(countries).map((key) => ({
        value: key,
        label: countries[key].name,
      }));
    },
    initials(): string {
      return getInitials(this.userData.username);
    },

    isSubmittable(): boolean {
      if (!this.userData || !this.isModified) return false;
      return (
        validateUserName(this.userData!.username) &&
        validateFirstname(this.userData!.firstname) &&
        validateLastname(this.userData!.lastname)
      );
    },

    professions() {
      return PROFESSIONS.map((val) => ({ value: val, label: val }));
    },

    languages() {
      return Object.keys(languages).map((key) => ({
        value: key,
        label: languages[key].name,
      }));
    },
  },
});
